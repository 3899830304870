// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AMozRrQWxMxHKPmLJ5G2 {\n  border: solid;\n  padding: 15px;\n  border-width: 2px;\n  border-color: var(--dark-blue);\n  background-color: white;\n  margin: 0px 0px 20px 0px;\n}\n\n\n.q_s4mSyFJde4GwInyUpP {\n  color: var(--dark-blue);\n  text-transform: none;\n}\n\n\n.u7JtwbRMGmyXSuOTMKLs {\n  background-color: white;\n}\n", "",{"version":3,"sources":["webpack://./app/javascript/dmp_opidor_react/src/components/assets/css/modal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,iBAAiB;EACjB,8BAA8B;EAC9B,uBAAuB;EACvB,wBAAwB;AAC1B;;;AAGA;EACE,uBAAuB;EACvB,oBAAoB;AACtB;;;AAGA;EACE,uBAAuB;AACzB","sourcesContent":[".info_box {\n  border: solid;\n  padding: 15px;\n  border-width: 2px;\n  border-color: var(--dark-blue);\n  background-color: white;\n  margin: 0px 0px 20px 0px;\n}\n\n\n.legend {\n  color: var(--dark-blue);\n  text-transform: none;\n}\n\n\n.modal_tabs {\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export var info_box = "AMozRrQWxMxHKPmLJ5G2";
export var legend = "q_s4mSyFJde4GwInyUpP";
export var modal_tabs = "u7JtwbRMGmyXSuOTMKLs";
export default ___CSS_LOADER_EXPORT___;
