// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js!./variable.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KOm3lIW82T6R8KLATVaA {\n  border: 2px solid var(--blue);\n  border-radius: 8px;\n  margin-top: 20px;\n}\n\n.UDvRCGizjuVqVidB7AqU {\n  border-bottom: 2px solid var(--blue);\n}", "",{"version":3,"sources":["webpack://./app/javascript/dmp_opidor_react/src/components/assets/css/stepper.module.css"],"names":[],"mappings":"AAEA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":["@import url(\"./variable.css\");\n\n.stepper_container {\n  border: 2px solid var(--blue);\n  border-radius: 8px;\n  margin-top: 20px;\n}\n\n.stepper_steps {\n  border-bottom: 2px solid var(--blue);\n}"],"sourceRoot":""}]);
// Exports
export var stepper_container = "KOm3lIW82T6R8KLATVaA";
export var stepper_steps = "UDvRCGizjuVqVidB7AqU";
export default ___CSS_LOADER_EXPORT___;
