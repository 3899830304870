// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IGW4kGcMfESNOX1I9ahQ {\n  background-color: var(--dark-blue);\n  padding: 15px;\n  border-radius: 10px;\n}\n\n.muPQpxE040kz4AXPjRpe {\n  color: white;\n  font-weight: bold;\n}\n\n.MZxpEEV5onjYztebVxyH {\n  position: absolute;\n  right: 10px;\n  top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./app/javascript/dmp_opidor_react/src/components/assets/css/form_selector.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX","sourcesContent":[".container {\n  background-color: var(--dark-blue);\n  padding: 15px;\n  border-radius: 10px;\n}\n\n.label {\n  color: white;\n  font-weight: bold;\n}\n\n.form_change_toggle {\n  position: absolute;\n  right: 10px;\n  top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = "IGW4kGcMfESNOX1I9ahQ";
export var label = "muPQpxE040kz4AXPjRpe";
export var form_change_toggle = "MZxpEEV5onjYztebVxyH";
export default ___CSS_LOADER_EXPORT___;
