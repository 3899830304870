import getConstant from './constants';
import { format, register } from 'timeago.js';

// Dynamically register all of the available locales if they have a timeago file


  
  
    
    import fr_FR from '../locale/fr_FR/timeago';
  


const TimeagoFactory = (() => {
  // Register all of the locales with Timeago
  
    register('fr_FR', fr_FR);
  

  const defaultLocale = 'fr_FR'

  const convertTime = (currentLocale, el) => {
    return format($(el).attr('datetime'), `${currentLocale}`);
  };

  return {
    render: (el) => {
      const currentLocale = getConstant('CURRENT_LOCALE') || defaultLocale;

      if (el.length > 1) {
        // If we were passed an array of JQuery elements then handle each one
        $(el).each((idx, e) => {
          $(e).text(convertTime(currentLocale, e));
        });
      } else {
        $(el).text(convertTime(currentLocale, el));
      }
    },
  };
})();

export default TimeagoFactory;
